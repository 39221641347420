import React from 'react';
import { useState, useEffect, useRef } from "react";
import "./requester.css"
import config from "../../config";
import AuthService from "../../Auth/auth";
import DotPulseLoader from "../../components/DotPulseLoader"
import refresh from "../../assets/images/refresh.svg"
import download from "../../assets/images/download.svg"
import updownarrow from "../../assets/images/updownArrows.svg"
import downloadArrow from "../../assets/images/downloadArrow.svg"
import line from "../../assets/images/line.svg"
import downArrow from "../../assets/images/downArrow.svg"
import freespaceIcon from "../../assets/images/pngs/logo.png"
import SearchBox from "../../components/searchInputBox/searchInputBox";
import FileDrop from "../../components/fileDrop/fileDrop";
import Button1 from "../../components/uploadButton/Button1";
import CheckBox from "../../components/checkBox/checkBox";
import Mod1 from "../../components/mod1/Mod1";
import Mod2 from "../../components/mod2/Mod2"
import alertIcon from "../../assets/images/alert.svg";
import successIcon from "../../assets/images/success.svg";
import '../../css/space-planner.css';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Border from "../../components/border";
import Modal from "../../components/modal";

const migrationStatuses = {
    ready: { bgColor: '#06b6d4', text: 'Ready' },
    inProgress: { bgColor: '#f97316', text: 'In Progress' },
    fail: { bgColor: '#ef4444', text: 'Fail' },
    success: { bgColor: '#22c55e', text: 'Success' },
};

function Requester() {
    //set states, use useeffects, create functions....
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [showFileDropComponent, setShowFileDropComponent] = useState(true);
    const [showDownloadDropdown, setShowDownloadDropdown] = useState(false);
    const [showChecklist, setShowChecklist] = useState(false);
    const [spaces, setSpaces] = useState({});
    const [loading, setLoading] = useState(false);
    const [showDeblessedPopUP, setShowDeblessedPopUp] = useState(false)
    const [loginKeyPopUp, setLoginKeyPopUp] = useState(false)
    const [modalPopUp, setModalPopUp] = useState(false);
    const dropdownRef = useRef(null);
    const [login, setLogin] = useState();
    const [storeBlessMacFromFile, setStoreBlessMacFromFile] = useState([])
    const allCategories = ["All", "Blessed", "Unblessed", "Invalid", "Escalate", "Success", "Failed"];
    const [selectedCategories, setSelectedCategories] = useState(allCategories);
    const [handleEmptyPopUp, setHandleEmptyPopUp] = useState(false);
    const [showActionBtn, setShowActionBtn] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);
    const [addNewValue, setAddNewValue] = useState([]);
    const [storeType, setStoreType] = useState();
    const [filteredSpaces, setFilteredSpaces] = useState({});
    const [filteredSpaceArray, setFilteredSpaceArray] = useState([]);
    const [uploadedFileExtension, setUploadedFileExtension] = useState(null);
    const [migrationInProgress, setMigrationInProgress] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    const [showDialog, setShowDialog] = useState(false);

    const initialSortDirections = {};
    // Initialize sort directions for each column
    Object.keys(spaces).forEach((key) => {
        initialSortDirections[key] = 'asc';
    });

    const [sortDirections, setSortDirections] = useState(initialSortDirections);
    const modalDetails = {
        secondaryText: "The Space to work efficiently",
        icon: freespaceIcon,
        inputBox: true,
        confirmButtonText: "Get Started"
    }

    let searchAPIResponse = {}

    const readFileData = (fileData) => {
        let blessIDAndMacID = fileData;
        let updatedJsonData = null;
        if (uploadedFileExtension === 'json') {
            if (validateJsonData(blessIDAndMacID)) {
                setHandleEmptyPopUp(true);
                return;
            }
            setMigrationInProgress(true);
            const [blessIds, jsonData] = mapJsonDataForTable(fileData);
            blessIDAndMacID = blessIds;
            updatedJsonData = jsonData;
        }
        readExcelDataAndPopulate(blessIDAndMacID, updatedJsonData);
    }

    const validateJsonData = (jsonData) => {
        let error = false;
        const requiredFields = ['account_id', 'bless_id', 'hardware_device_id', 'product_type', 'factory_event_metadata'];
        jsonData.forEach((json) => {
            requiredFields.forEach((field) => {
                if (!json.hasOwnProperty(field) || json[field] === "") {
                    error = true;
                }
            });
        })
        return error;
    }

    const mapJsonDataForTable = (fileData) => {
        const blessIds = fileData.map(f => f.bless_id);
        const jsonData = fileData.reduce((acc, f) => {
            acc[f.bless_id] = f;
            return acc;
        }, {});
        return [blessIds, jsonData];
    }

    const readExcelDataAndPopulate = async (blessIDAndMacID, jsonData = []) => {
        let type;
        const validBlessIds = [];
        const validMacIds = [];
        const wrongIdInput = [];
        let invalidBlessMacIds = [];
        setStoreBlessMacFromFile(blessIDAndMacID)

        if (blessIDAndMacID) {
            blessIDAndMacID.forEach(item => {
                const itemString = String(item);
                if (itemString.length === 10) {
                    const intValue = parseInt(item, 10);
                    if (!isNaN(intValue)) {
                        validBlessIds.push(intValue);
                    }
                    else {
                        wrongIdInput.push(item);
                    }

                } else if (item.length === 16 && typeof item === 'string') {
                    validMacIds.push(item);
                }
                else {
                    wrongIdInput.push(item);
                }
            });
            if (validBlessIds.length > 0) {
                type = 'blessId';
                setStoreType(type);
                searchAPIResponse = await AuthService.searchAPIModified(validBlessIds, type);

                if (searchAPIResponse.length === 0) {
                    setDialogData({
                        icon: alertIcon,
                        showActionBar: false,
                        body: (
                            <>
                                <div className="secondaryText">
                                    The device is not available in these regions.
                                </div>
                            </>
                        )
                    })
                    setShowDialog(true);
                    return;
                }

                let responseDeviceIds = searchAPIResponse.map(item => item?.device?.blessId);
                invalidBlessMacIds = validBlessIds.filter(blessId => !responseDeviceIds.includes(blessId));
            }
            else if (validMacIds.length > 0) {
                type = 'macId';
                setStoreType(type);
                searchAPIResponse = await AuthService.searchAPIModified(validMacIds, type);

                if (searchAPIResponse.length === 0) {
                    setDialogData({
                        icon: alertIcon,
                        showActionBar: false,
                        body: (
                            <>
                                <div className="secondaryText">
                                    The device is not available in these regions.
                                </div>
                            </>
                        )
                    })
                    setShowDialog(true);
                    return;
                }

                let responseDeviceIds = searchAPIResponse.map(item => item?.device?.hardware?.deviceId);
                invalidBlessMacIds = validMacIds.filter(macId => !responseDeviceIds.includes(macId));
            }
            const allInvalidIds = [...invalidBlessMacIds, ...wrongIdInput];

            let apidataObject = {};
            if (searchAPIResponse.length === 0) {
                Object.entries(jsonData).forEach(([blessId, data]) => {
                    apidataObject[blessId] = {
                        json: data,
                        productType: data?.product_type || "--",
                        blessId: data?.bless_id || "--",
                        macId: data?.hardware_device_id || "--",
                        modelName: data?.model_name || "--",
                        deviceType: data?.device_type || "--",
                        migrationStatus: migrationStatuses.ready,
                    }
                });
                setShowFileDropComponent(false)
                setSpaces(apidataObject);
                setFilteredSpaces(apidataObject);
                setFilteredSpaceArray(Object.values(apidataObject));
                setLoading(false)
            } else {
                try {
                    setShowFileDropComponent(false);
                    const entries = Object.entries(searchAPIResponse);
                    const promises = entries.map(async ([, value]) => {
                        const extractedContent = value?.device?.hardware?.deviceId;
                        let summaryApiData = await AuthService.summaryApi(extractedContent);    // Summary API takes macId in the URL of API
                        const devicegroupIdValue = summaryApiData?.response?.data?.relationships?.devicegroup?.id;
                        const summaryProductId = summaryApiData?.response?.data?.relationships?.product?.id;

                        let productIdData = await AuthService.productType(summaryProductId);    // Product API gives type of product by passing productId obtained from summary API
                        let deviceIdData = await AuthService.modelName(devicegroupIdValue);     // Model API gives model name by passing deviceId obtianed from summary API

                        apidataObject[value.device.blessId] = {
                            json: jsonData ? jsonData?.[value.device.blessId] : null,
                            productType: productIdData?.response?.data?.attributes?.name || jsonData?.[value.device.blessId].product_type || "--",
                            accountId: value.account?.id || "--",
                            accountName: value.account?.name || "--",
                            installId: value.device?.id || "--",
                            blessId: value.device?.blessId || jsonData?.[value.device.blessId].bless_id || "--",
                            macId: value?.device?.hardware?.deviceId || jsonData?.[value.device.blessId].hardware_device_id || "--",
                            floorId: value.location?.id || "--",
                            floorStatus: value.location?.showInMenu ? "Live" : "Unlive",
                            space: value.spaces[0]?.id || "--",
                            modelName: deviceIdData?.response?.data?.attributes?.name || jsonData?.[value.device.blessId].model_name || "--",
                            deviceType: deviceIdData?.response?.data?.type === "production_devicegroup" ? "Production" : (deviceIdData?.response?.data?.type) === "development_devicegroup" ? "Development" : (deviceIdData?.response?.data?.type) === "factoryfixture_devicegroup" ? "Factory" : jsonData?.[value.device.blessId]?.device_type || "--",
                            status: deviceIdData?.response?.data?.type === "production_devicegroup" ? "Blessed" : (deviceIdData?.response?.data?.type) ? "Unblessed" : "Invalid",
                            deviceGroupId: summaryApiData?.response?.data?.relationships?.devicegroup?.id,
                            bgColor: deviceIdData?.response?.data?.type === "production_devicegroup" ? "#748ADF" : (deviceIdData?.response?.data?.type) ? "#667799" : "#BBBBBB",
                            migrationStatus: deviceIdData?.response?.data?.type === "production_devicegroup" ? migrationStatuses.ready : null
                        };
                    });

                    if (allInvalidIds.length > 0) {
                        allInvalidIds.forEach(item => {
                            apidataObject[item] = {
                                productType: "--",
                                accountId: "--",
                                accountName: "--",
                                installId: "--",
                                blessId: typeof item == 'number' ? item : "--",
                                macId: typeof item == 'string' ? item : "--",
                                floorId: "--",
                                floorStatus: "--",
                                space: "--",
                                modelName: "--",
                                deviceType: "--",
                                status: "Invalid",
                                deviceGroupId: "--",
                                bgColor: "#BBBBBB",
                                migrationStatus: null
                            };
                        })
                    }

                    if (validBlessIds.length === 0 && validMacIds.length === 0 && allInvalidIds.length === 0) {
                        setShowFileDropComponent(false)
                        setHandleEmptyPopUp(true)
                    }

                    setLoading(true);

                    await Promise.all(promises);
                    setSpaces(apidataObject);
                    setFilteredSpaces(apidataObject);
                    setFilteredSpaceArray(Object.values(apidataObject));
                    setLoading(false)

                } catch (error) {
                    setLoading(false);
                    console.error("Error fetching and processing data:", error);
                }
            }
        }
        else {
            setShowFileDropComponent(false)
            setHandleEmptyPopUp(true)
        }
    };

    //Check the no of only selected devices
    const spacesLength = Object.entries(isCheck).length;
    const liveStatusCount = Object.entries(filteredSpaces).filter(([data,]) => isCheck.includes(data)).reduce((count, [, blessId]) => {   // To check the devices assigned on Live floor
        if (blessId.floorStatus === "Live") {
            return count + 1;
        }
        return count;

    }, 0);

    const loginKey = (value) => {
        setLogin(value)
    }

    const addNewBtnFunctionality = () => {

        if (storeType) {
            setShowFileDropComponent(false)
            setShowAddNew(true)
        }
        else {
            setShowFileDropComponent(true)
            setShowAddNew(false)
        }
    }

    const newDevice = (value) => {
        setAddNewValue(value)
    }

    const addNewFunction = () => {
        const newValues = addNewValue.split(',').map(value =>
            value.trim().replace(/-/g, '')
        );

        const validValues = newValues.filter(value => {
            if ((storeType === "blessId" && value.length === 10) ||
                (storeType === "macId" && value.length === 16)) {
                return true;
            } else {
                alert(
                    storeType === "blessId"
                        ? "Bless ID should be 10 characters long."
                        : "Mac ID should be 16 characters long."
                );
                return false;
            }
        });


        if (validValues.length > 0) {
            setShowAddNew(false);
            const combinedArray = [...storeBlessMacFromFile, ...validValues];
            readExcelDataAndPopulate(combinedArray);
        }
    }

    const deblessedBtnFunctionality = () => {
        if (Object.keys(filteredSpaces).length > 0) {

            if (isCheck.length === 0) {
                window.alert("Please select entries that you want to debless")
            }
            else {
                //Display First Modal
                setShowDeblessedPopUp(true)
            }
        }
    }

    const migrateOutBtnFunctionality = async () => {
        try {
            setLoading(true);
            if (Object.keys(filteredSpaces).length > 0) {
                if (isCheck.length === 0) {
                    window.alert("Please select entries that you want to migrate out")
                }
                else {
                    setMigrationInProgress(true);

                    // Step 1: Set the migration status to in progress for each of the selected Bless IDs.
                    setMigrationStatusInProgress();

                    //Step 2 : Check if the device floor status is set to "live" or "unlive."
                    const liveDevices = Object.entries(filteredSpaces)
                        .filter(([blessId, data]) => isCheck.includes(blessId) && data.floorStatus === 'Live')
                    if (liveDevices.length > 0) {
                        setLoading(false);
                        setMigrationInProgress(false);
                        setDialogData({
                            icon: alertIcon,
                            showActionBar: false,
                            body: (
                                <>
                                    <div className="secondaryText">
                                        {liveDevices.length} device{liveDevices.length > 1 ? 's are' : ' is'} currently active on the floor.
                                    </div>
                                </>
                            )
                        })
                        setShowDialog(true);
                        return;
                    }
                    // Step 3: Deactivate unlived devices in the current region, if present.
                    let reportJson = null;
                    let missingBlessIds = [];
                    const toggleApiResponse = await AuthService.toggleDeviceConfigStatus(isCheck.map((ic) => parseInt(ic)), 0);
                    if (toggleApiResponse && toggleApiResponse.success && toggleApiResponse.success.ids.length > 0) {

                        // Step 4: Retrieve the Factory Event data for the specified devices.
                        const fetchFactoryEventResponse = await AuthService.fetchFactoryEvents(toggleApiResponse.success.ids);
                        missingBlessIds = fetchFactoryEventResponse.map((fe) => fe.bless_id).filter((fe) => toggleApiResponse.success.ids.includes(fe));
                        // Step 5 : Create a report for uploading data between the UK and US regions.
                        reportJson = toggleApiResponse.success.ids.map((blessId) => {
                            const factoryEvent = fetchFactoryEventResponse.find((fe) => fe.bless_id === blessId);
                            return {
                                account_id: filteredSpaces[blessId].accountId,
                                bless_id: blessId,
                                hardware_device_id: filteredSpaces[blessId].macId,
                                product_type: filteredSpaces[blessId].productType,
                                model_name: filteredSpaces[blessId].modelName,
                                device_type: filteredSpaces[blessId].deviceType,
                                factory_event_metadata: factoryEvent ? JSON.stringify(factoryEvent) : null,
                            }
                        })
                    } else {
                        showErrorMessage(toggleApiResponse?.message || 'Something went wrong. Please contact the administrator.');
                    }

                    // Step 6: Set the migration status for each of the selected Bless IDs.
                    let tempFilteredSpaces = filteredSpaces;
                    Object.entries(filteredSpaces).forEach(([blessId,]) => {
                        if (isCheck.includes(blessId)) {
                            if (toggleApiResponse.success.ids.length > 0 && toggleApiResponse.success.ids.includes(parseInt(blessId))) {
                                tempFilteredSpaces[blessId].migrationStatus = { ...migrationStatuses.success, message: toggleApiResponse.success.message };
                            } else if (missingBlessIds.length > 0) {
                                tempFilteredSpaces[blessId].migrationStatus = { ...migrationStatuses.fail, message: `The device's Factory Event data is missing.` };
                            } else {
                                tempFilteredSpaces[blessId].migrationStatus = { ...migrationStatuses.fail, message: toggleApiResponse.invalid.message };
                            }
                        } else {
                            tempFilteredSpaces[blessId].migrationStatus = null;
                        }
                    })
                    setFilteredSpaces(tempFilteredSpaces);
                    setFilteredSpaceArray(Object.values(tempFilteredSpaces));

                    // Step 7: reset action button and show report
                    setIsCheck([]);
                    setIsCheckAll(false);
                    setShowActionBtn(false);
                    setDialogData({
                        size: "large",
                        icon: successIcon,
                        showActionBar: false,
                        dismissOnClick: false,
                        body: (
                            <>
                                <div className="secondaryText">
                                    Migration for {toggleApiResponse.success.ids.length} device{toggleApiResponse.success.ids.length > 1 ? 's' : ''} has been successfully completed.
                                </div>
                                {toggleApiResponse?.invalid && toggleApiResponse?.invalid?.ids && toggleApiResponse?.invalid?.ids.length > 0 && (
                                    <div className="">
                                        <table className="">
                                            <caption>Failed Devices</caption>
                                            <tr>
                                                <th>Device Bless Id</th>
                                                <th>Reasons</th>
                                            </tr>
                                            {toggleApiResponse?.invalid?.ids.forEach(element => {
                                                return (
                                                    <tr>
                                                        <td>{element}</td>
                                                        <td>{toggleApiResponse?.invalid?.message}</td>
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    </div>
                                )}
                                <div className="secondaryText">
                                    Please click to download the migration report.
                                </div>
                                <button
                                    className="downloadBtn"
                                    type="button"
                                    onClick={() => {
                                        handleDownload('json', reportJson);
                                        setShowDialog(false);
                                    }}
                                >
                                    Download Migration Report
                                </button>
                            </>
                        )
                    })
                    setShowDialog(true)
                }
            }
        } catch (error) {
            console.log("Error: ", error);
        } finally {
            setLoading(false);
        }
    }


    const migrateInBtnFunctionality = async () => {
        try {
            setLoading(true);
            if (Object.keys(filteredSpaces).length > 0) {
                if (isCheck.length === 0) {
                    window.alert("Please select entries that you want to migrate out")
                }
                else {
                    // Step 1: Set the migration status to in progress for each of the selected Bless IDs.
                    setMigrationStatusInProgress();

                    let response = {};
                    let failDevices = [];
                    let tempFilteredSpaces = { ...filteredSpaces };
                    Object.keys(filteredSpaces).forEach(blessId => !isCheck.includes(blessId) && delete tempFilteredSpaces[blessId]);
                    // Step 2: Create a factory event if it does not exist, or update the factory event data with the uploaded information.
                    await Promise.all(
                        Object.entries(tempFilteredSpaces).map(async ([blessId, filteredSpace]) => {
                            const storeFactoryEventResponse = await AuthService.storeFactoryEvents([filteredSpace.json]);
                            if (storeFactoryEventResponse === "OK") {
                                response[blessId] = {
                                    status: "success",
                                }
                            } else {
                                response[blessId] = {
                                    status: "fail",
                                    message: "Failed to create or update the factory event.",
                                }
                                failDevices.push(blessId);
                            }
                        }),
                    );

                    tempFilteredSpaces = removeFailedDevice(tempFilteredSpaces, failDevices);

                    // Step 3: Run the Event API to create or update the device configuration and factory event.
                    if (Object.values(tempFilteredSpaces).length > 0) {
                        await Promise.all(
                            Object.entries(tempFilteredSpaces).map(async ([blessId, filteredSpace]) => {
                                const factory_event_data = JSON.parse(filteredSpace.json.factory_event_metadata);
                                const payload = {
                                    label: parseInt(blessId),
                                    configparams: {
                                        deviceid: filteredSpace.macId,
                                        macaddress: filteredSpace.macId.slice(4),
                                        agent_url: factory_event_data.agent_url,
                                        factory_fixture_url: factory_event_data.agent_url,
                                    },
                                    data: cleanJSON(factory_event_data.data),
                                };
                                const eventResponse = await AuthService.event(payload);
                                if (eventResponse === "OK" || (typeof eventResponse === 'string' && eventResponse.includes('{') && eventResponse.includes('}') && typeof JSON.parse(eventResponse) === 'object')) {
                                    response[blessId] = {
                                        status: "success",
                                    }
                                } else {
                                    response[blessId] = {
                                        status: "fail",
                                        message: "Failed to create or update the device config.",
                                    }
                                    failDevices.push(blessId);
                                }
                            }),
                        );
                    }

                    tempFilteredSpaces = removeFailedDevice(tempFilteredSpaces, failDevices);

                    // Step 4: Activate devices in the current region, if present.
                    if (Object.values(tempFilteredSpaces).length > 0) {
                        const blessId = Object.keys(tempFilteredSpaces).map((fs) => parseInt(fs));
                        const toggleApiResponse = await AuthService.toggleDeviceConfigStatus(blessId, 1);
                        if (toggleApiResponse.invalid?.ids?.length > 0 || toggleApiResponse.live?.ids?.length > 0) {
                            failDevices = toggleApiResponse.invalid.ids.concat(toggleApiResponse.live.ids, failDevices);
                            const failAPIDevices = toggleApiResponse.invalid.ids.concat(toggleApiResponse.live.ids);
                            failAPIDevices?.map(fd => {
                                response[blessId] = {
                                    status: "fail",
                                    message: "Unable to update the device configuration status as the device is either live or invalid.",
                                }
                            })
                        } else if (toggleApiResponse.success.ids.length > 0) {
                            toggleApiResponse.success.ids.map(blessId => {
                                response[blessId] = {
                                    status: "success",
                                }
                            })
                        }
                    }
                    tempFilteredSpaces = removeFailedDevice(tempFilteredSpaces, failDevices);

                    // Step 5: Retrieve the product type for each device.
                    if (Object.values(tempFilteredSpaces).length > 0) {
                        await Promise.all(
                            Object.entries(tempFilteredSpaces).map(async ([blessId, filteredSpace]) => {
                                const productTypeResponse = await AuthService.fetchProductType(encodeURIComponent(filteredSpace.productType));
                                if (productTypeResponse.length > 0) {
                                    response[blessId] = {
                                        status: "success",
                                    }
                                    tempFilteredSpaces[blessId].productTypeId = productTypeResponse[0].id;
                                } else {
                                    response[blessId] = {
                                        status: "fail",
                                        message: "Product type not found.",
                                    }
                                    failDevices.push(blessId);
                                }
                            })
                        )
                    }

                    tempFilteredSpaces = removeFailedDevice(tempFilteredSpaces, failDevices);

                    // Step 6: Run the Items API to create an entry in the Item table.
                    if (Object.values(tempFilteredSpaces).length > 0) {
                        await Promise.all(
                            Object.entries(tempFilteredSpaces).map(async ([blessId, filteredSpace]) => {
                                const payload = {
                                    type_id: filteredSpace.productTypeId,
                                    wifi: {
                                        ssid: config.REACT_APP_WIFI_SSID_NAME
                                    },
                                }
                                const itemResponse = await AuthService.items(filteredSpace?.macId, payload);
                                if (itemResponse === "OK") {
                                    response[blessId] = {
                                        status: "success",
                                    }
                                } else {
                                    response[blessId] = {
                                        status: "fail",
                                        message: "The Item API request failed.",
                                    }
                                    failDevices.push(blessId);
                                }
                            })
                        )
                    }

                    tempFilteredSpaces = removeFailedDevice(tempFilteredSpaces, failDevices);

                    // Step 7: Set the migration status for each of the selected Bless IDs.
                    setFilteredSpaces((prevFilteredSpaces) => {
                        let tempFilteredSpaces = { ...prevFilteredSpaces };
                        Object.entries(response).forEach(([blessId, res]) => {
                            if (tempFilteredSpaces[blessId]) {
                                tempFilteredSpaces[blessId].migrationStatus = res.status === 'success' ?
                                    migrationStatuses.success : { ...migrationStatuses.fail, message: res.message }
                            }
                        });

                        return tempFilteredSpaces;
                    })

                    // Step 8: reset action button and show report
                    setIsCheck([]);
                    setIsCheckAll(false);
                    setShowActionBtn(false);
                    const successDevices = Object.keys(response).filter((key) => response[key].status == "success" && key);
                    setDialogData({
                        size: "large",
                        icon: successDevices.length > 0 ? successIcon : alertIcon,
                        showActionBar: false,
                        showCloseButton: true,
                        body: (
                            <>
                                {successDevices.length > 0 && (
                                    <div className="secondaryText">
                                        Migration for {successDevices.length} device{successDevices.length > 1 ? 's' : ''} has been successfully completed.
                                    </div>
                                )}
                                {failDevices && failDevices.length > 0 && (
                                    <div className="deviceResultTable">
                                        <table className="">
                                            <caption><strong>Failed Devices</strong></caption>
                                            <tr>
                                                <th>Device Bless Id</th>
                                                <th>Reasons</th>
                                            </tr>
                                            {failDevices?.map(element => (
                                                <tr key={element}>
                                                    <td>{element}</td>
                                                    <td>{response[element]?.message}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                )}
                            </>
                        )
                    })
                    setShowDialog(true)
                }
            }
        } catch (error) {
            console.log("Error: ", error);
        } finally {
            setLoading(false);
        }
    }

    const setMigrationStatusInProgress = () => {
        let tempFilteredSpaces = filteredSpaces;
        Object.entries(filteredSpaces)?.forEach(([blessId,]) => {
            if (isCheck.includes(blessId)) {
                tempFilteredSpaces[blessId].migrationStatus = migrationStatuses.inProgress;
            }
        })
        setFilteredSpaces(tempFilteredSpaces);
        setFilteredSpaceArray(Object.values(tempFilteredSpaces));
    }

    const removeFailedDevice = (DeviceArray, failDevices = []) => {
        if (failDevices.length > 0) {
            failDevices.forEach((fd) => delete DeviceArray[fd]);
        }
        return DeviceArray;
    }

    const cleanJSON = (str) => {
        // Remove the surrounding triple quotes
        const cleanStr = str.replace(/^(?:"""|""")$/g, ''); // Grouped to clarify precedence
        // Parse the string into a JSON object
        try {
            const jsonObj = JSON.parse(cleanStr);
            return jsonObj
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    }

    const deblessedAPICallingFromMacAddress = async () => {
        setLogin('');
        setLoginKeyPopUp(false);
        setLoading(true);
        Object.entries(spaces)
            .filter(([data,]) => isCheck.includes(data))
            .map(async ([, blessId]) => {
                let deblessedData = await AuthService.deblessedAPI(login, blessId.macId, blessId.deviceGroupId);
                if (deblessedData.response === "OK") {
                    let factory_event = await AuthService.factoryEvent(blessId.macId, null);
                    let device_config = await AuthService.deviceConfig(blessId.macId, null);

                    if (factory_event[0]?.codeStatus === 200 && device_config[0]?.codeStatus === 200) {  //Bond breaks, 2 api successfully runs
                        setLoading(false)
                        Object.entries(spaces)
                            .filter(([data,]) => isCheck.includes(data))
                            .forEach(async ([, blessId]) => {
                                blessId.status = "Success"
                                blessId.bgColor = "#4ECB71"
                            });
                    } else if (factory_event[0]?.codeStatus === 400 || device_config[0]?.codeStatus === 400) { //Bond breaks, but any 1 API gives 400 status code
                        setLoading(false)
                        Object.entries(spaces)
                            .filter(([data,]) => isCheck.includes(data))
                            .forEach(async ([, blessId]) => {
                                blessId.status = "Escalate"
                                blessId.bgColor = "#ffb700"
                            });
                    } else {
                        // Bond breaks, but any 1 API gives other error status code
                        setLoading(false)
                        Object.entries(spaces)
                            .filter(([data,]) => isCheck.includes(data))
                            .forEach(async ([, blessId]) => {
                                blessId.status = "Escalate"
                                blessId.bgColor = "#ffb700"
                            });
                    }
                    setIsCheck([]);
                    setIsCheckAll(false)
                }
                else if (deblessedData?.response?.errors[0]?.status === '403') {  //Bond break fails 
                    setLoading(false)
                    setModalPopUp(true);
                }
                else {
                    setLoading(false)
                    Object.entries(spaces)
                        .filter(([data,]) => isCheck.includes(data))
                        .map(async ([, blessId]) => {
                            blessId.status = "Failed"
                            blessId.bgColor = "#FF5E5E"
                        });
                    setIsCheck([]);
                    setIsCheckAll(false)

                }
            });
    }

    useEffect(() => {
        if (isCheckAll) {
            const validIsChecked = Object.entries(filteredSpaces).reduce(
                (a, [data, blessId]) => {
                    if (blessId.deviceType === "Production" && data != "undefined") {
                        a.push(data);
                    }
                    return a;
                }, []
            );
            if (validIsChecked.length > 0) {
                setShowActionBtn(true);
                setIsCheck(validIsChecked);
                return;
            }
        }
        setIsCheck([]);
        setShowActionBtn(false);
    }, [isCheckAll]);

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);
    };

    const handleClick = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        setShowActionBtn(true);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id));
            if ((isCheck.length - 1) === 0) {
                setShowActionBtn(false);
                setIsCheckAll(false);

            }
        }
    };
    const handleFunc = (e) => {
        const { id, checked } = e.target;
        setSelectedCategories((prevSelected) => {
            if (id === "All") {
                return checked ? [...allCategories] : []; // Select all if checked, clear all if unchecked
            } else {
                let updatedSelected;
                if (checked) {
                    // Adding a category
                    updatedSelected = [...prevSelected, id];
                    // If all individual categories are selected, add "All"
                    if (updatedSelected.length === allCategories.length - 1) {
                        updatedSelected = [...allCategories];
                    }
                } else {
                    // Removing a category
                    updatedSelected = prevSelected.filter(category => 
                        category !== id && category !== "All"
                    );
                }
                return updatedSelected;
            }
        });
    };

    const toggleRefresh = () => {
        if (storeBlessMacFromFile.length > 0) {
            readExcelDataAndPopulate(storeBlessMacFromFile);
            setSelectedCategories(['All']);
        }
        // else{

        // }
    }
    const toggleDropDown = () => {
        setShowDownloadDropdown(true)
    }
    const toggleChecklist = () => {
        setShowChecklist(true)
    }

    const handleSearch = (searchText) => {

        if (searchText === '') {
            setFilteredSpaces(spaces); // Restore original data
            setFilteredSpaceArray(Object.values(spaces));

        } else {
            const filteredData = Object.values(spaces).filter((blessId) => {
                return Object.values(blessId).some((value) => {
                    const lowerSearchText = searchText.toLowerCase();
                    if (typeof value === 'string') {
                        return value.toLowerCase().includes(lowerSearchText);
                    } else if (typeof value === 'number') {
                        return value.toString().includes(lowerSearchText);
                    }
                    return false;
                });
            });

            setFilteredSpaces(filteredData);
            setFilteredSpaceArray(Object.values(filteredData));

            if (filteredData.length === 0) {
                // Display an alert or take any other action for no matching results
                alert('No matching results found');
            }
        }
    };
    const handleClearSearch = () => {
        setFilteredSpaces(spaces)
        setFilteredSpaceArray(Object.values(spaces));
    }

    const sortData = (column) => {
        const newSortDirections = { ...sortDirections };
        newSortDirections[column] = newSortDirections[column] === 'asc' ? 'desc' : 'asc';
        setSortDirections(newSortDirections);

        const spacesArray = Object.values(spaces); // Convert data object to array to apply sort function.

        const sorted = [...spacesArray].sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (aValue === '--' && bValue === '--') {
                return 0; // Treat -- values as equal
            }

            if (aValue === '--') {
                return newSortDirections[column] === 'asc' ? 1 : -1; // -- values after non --
            }

            if (bValue === '--') {
                return newSortDirections[column] === 'asc' ? -1 : 1; // -- values after non --
            }

            if (typeof aValue === 'string') {
                return newSortDirections[column] === 'asc'
                    ? aValue.toLowerCase() < bValue.toLowerCase()
                        ? -1
                        : 1
                    : aValue.toLowerCase() > bValue.toLowerCase()
                        ? -1
                        : 1;
            } else if (typeof aValue === 'number') {
                return newSortDirections[column] === 'asc' ? aValue - bValue : bValue - aValue;
            }

            return 0; // Default case if types are not string or number
        });
        setFilteredSpaces(sorted);
        setFilteredSpaceArray(Object.values(sorted));
    };


    useEffect(() => {  // reference for download list hiding
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDownloadDropdown(false);
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    useEffect(() => {  // reference for checklist div hiding
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowChecklist(false);
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);


    const handleDownload = (format, migrationReportJson = null) => {
        // Prepare table data
        const tableData = [...document.querySelectorAll('#deviceTable tr')].map(row =>
            [...row.children].map(cell => cell.textContent)
        );
        const tableEmptyCheck = [...document.querySelectorAll('#deviceTable td')]
        const formattedDateTime = `${String(new Date().getDate()).padStart(2, '0')}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${new Date().getFullYear()} ${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}:${String(new Date().getSeconds()).padStart(2, '0')}`;

        let content;
        if (format === 'csv') {
            content = tableData.map(row => row.join(',')).join('\n');
            if (tableEmptyCheck.length === 0) {
                alert.window = "Please Upload the file";
            }
            else {
                const blob = new Blob([content], { type: 'text/csv;charset=utf-8' });

                saveAs(blob, ` device_list_${formattedDateTime}.${format}`);
            }

        } else if (format === 'xlsx') {
            const sheetName = 'Sheet1';
            let data1 = tableData ? tableData.map(d1 => Object.values(d1)) : [];
            let newData1 = data1.map(d1 => Object.values(d1));
            const worksheet = XLSX.utils.aoa_to_sheet([]);
            let aoa = [];

            newData1.forEach(a => {
                if (a != null) {
                    aoa.push([...a.map(d1 => d1 != null ? d1.toString() : '')]);
                }
            });

            const newworksheet = XLSX.utils.sheet_add_aoa(worksheet, aoa, { origin: 0 });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, newworksheet, sheetName);
            if (data1.length > 1) {
                XLSX.writeFile(workbook, `device_list_${formattedDateTime}.${format}`);

            } else {
                alert.window = "Please Upload the file";
            }
        } else if (format === 'json') {
            const jsonString = JSON.stringify(migrationReportJson, null, 2);
            const blob = new Blob([jsonString], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            // Create an anchor element
            const a = document.createElement("a");
            a.href = url;
            a.download = `migration-out-report-${formattedDateTime}.${format}`;
            a.click();
            // Clean up by revoking the object URL
            URL.revokeObjectURL(url);
        }
    };

    const checkTableColumnVisiability = (key) => {
        return (filteredSpaceArray.length === 0 || filteredSpaceArray.filter(fs => fs?.[key] && fs?.[key] !== null && fs?.[key] !== "--").length > 0)
    }

    const showErrorMessage = (message) => {
        setDialogData({
            icon: alertIcon,
            showActionBar: false,
            showCloseButton: true,
            body: (
                <>
                    <div className="secondaryText">{message}</div>
                </>
            )
        })
        setShowDialog(true);
    }

    return (
        <>
            <div className="headerPrimary">
                <p>Device List</p>
            </div>
            <Border />
            {/* <div className="headerSecondary" style={{ width: '50%', paddingLeft: '6.85rem' }}>
                <img src={timer} alt="clock"></img>
                <p className="secondaryText">Session Time:</p>
                <p className="secondaryText">10:00</p>
            </div>
            <Border /> */}

            <div className="headerTernary">
                <div className="searchPanel">
                    <SearchBox
                        onSearch={handleSearch}
                        onClear={handleClearSearch}
                    />
                    <div className="mainBodyFilter">
                        <p onClick={toggleChecklist} style={{ cursor: "pointer" }}>Show Only</p>
                        <img src={downArrow} className="downArrow" style={{ cursor: "pointer" }} onClick={toggleChecklist} alt="icon"></img>
                        {showChecklist && (
                            <div className={`dropdownDivChecklist ${showChecklist && 'show'}`} ref={dropdownRef}>
                                <ul className="dropdown-menu-checklist"><b>Show Only</b>
                                    {allCategories.map((category) => (
                                        <li key={category}>
                                            <CheckBox
                                                id={category}
                                                handleClick={handleFunc}
                                                isChecked={selectedCategories.includes(category)}
                                            /> {category}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )
                        }
                        {/* <SaveButton /> */}
                    </div>
                </div>
                <div className="handlingButtons">
                    <img src={refresh} alt="RefreshBtn"
                        // onClick={() => {
                        //     readExcelDataAndPopulate(storeBlessMacFromFile);
                        //     setSelectedCategories(['All']);
                        // }} 
                        onClick={toggleRefresh}
                        style={{ cursor: "pointer" }}></img>
                    <Border />
                    <div className="downloadBtnAndArrow" onClick={toggleDropDown} ref={dropdownRef}>
                        <img src={download} alt="DownloadBtn"></img>
                        <img src={line} alt="icon"></img>
                        <img src={downloadArrow} alt="DownloadBtnArrow"></img>
                        {showDownloadDropdown && (
                            <div className="dropdownDiv">
                                <ul className="dropdown-menu">
                                    <li onClick={() => handleDownload('csv')}>CSV</li>
                                    <li onClick={() => handleDownload('xlsx')}>Excel</li>
                                </ul>
                            </div>
                        )
                        }


                    </div>
                    {/* <img src={upload} alt="UploadBtn"></img> */}

                    {uploadedFileExtension !== 'json' &&
                        <Button1
                            text={"Add New"}
                            style={{ marginBottom: "0", textColor: "white", borderRadius: "8px", width: "6.3rem", minHeight: "38px" }}
                            background={"linear-gradient(90deg, rgb(247, 112, 125) 100%, rgb(242, 145, 115) 100%)"} color={"#ffffff"}
                            onClick={addNewBtnFunctionality}
                        />
                    }
                    {showActionBtn && (
                        <>
                            {
                                (uploadedFileExtension === 'xlsx' || uploadedFileExtension === 'csv') ? (
                                    <>
                                        <Button1
                                            text={"Debless"}
                                            style={{ marginBottom: "0", textColor: "white", borderRadius: "8px", width: "6.3rem", minHeight: "38px" }}
                                            background={"linear-gradient(90deg, rgb(247, 112, 125) 100%, rgb(242, 145, 115) 100%)"} color={"#ffffff"}
                                            onClick={deblessedBtnFunctionality}
                                        />
                                        <Button1
                                            text={"Migrate Out"}
                                            style={{ marginBottom: "0", textColor: "white", borderRadius: "8px", width: "6.3rem", minHeight: "38px" }}
                                            background={"linear-gradient(90deg, rgb(247, 112, 125) 100%, rgb(242, 145, 115) 100%)"} color={"#ffffff"}
                                            onClick={migrateOutBtnFunctionality}
                                        />
                                    </>
                                ) : uploadedFileExtension === 'json' && (
                                    <Button1
                                        text={"Migrate In"}
                                        style={{ marginBottom: "0", textColor: "white", borderRadius: "8px", width: "6.3rem", minHeight: "38px" }}
                                        background={"linear-gradient(90deg, rgb(247, 112, 125) 100%, rgb(242, 145, 115) 100%)"} color={"#ffffff"}
                                        onClick={migrateInBtnFunctionality}
                                    />
                                )
                            }
                        </>
                    )}

                </div>


            </div >
            <div className="excelDataBlock">
                <table className="table" id="deviceTable">
                    <thead>
                        <tr>
                            <th>Product Type<img src={updownarrow} onClick={() => sortData('productType')} alt="icon"></img></th>
                            {checkTableColumnVisiability('accountName') && (
                                <th>Account<img src={updownarrow} onClick={() => sortData('accountName')} alt="icon"></img></th>
                            )}
                            {checkTableColumnVisiability('installId') && (
                                <th>Install ID<img src={updownarrow} onClick={() => sortData('installId')} alt="icon"></img></th>
                            )}
                            <th>Bless ID<img src={updownarrow} onClick={() => sortData('blessId')} alt="icon"></img></th>
                            <th>Mac ID<img src={updownarrow} onClick={() => sortData('macId')} alt="icon"></img></th>
                            {checkTableColumnVisiability('floorId') && (
                                <th>Floor ID <img src={updownarrow} onClick={() => sortData('floorId')} alt="icon"></img></th>
                            )}
                            {checkTableColumnVisiability('floorStatus') && (
                                <th>Floor Status <img src={updownarrow} onClick={() => sortData('floorStatus')} alt="icon"></img></th>
                            )}
                            {checkTableColumnVisiability('space') && (
                                <th>Space <img src={updownarrow} onClick={() => sortData('space')} alt="icon"></img></th>
                            )}
                            <th>Model Name <img src={updownarrow} onClick={() => sortData('modelName')} alt="icon"></img></th>
                            <th>Device Type <img src={updownarrow} onClick={() => sortData('deviceType')} alt="icon"></img></th>
                            {checkTableColumnVisiability('status') && <th>Status <img src={updownarrow} onClick={() => sortData('status')} alt="icon"></img></th>}
                            {migrationInProgress && <th>Migration Status <img src={updownarrow} onClick={() => sortData('status')} alt="icon"></img></th>}
                            <th><CheckBox
                                handleClick={handleSelectAll}
                                isChecked={isCheckAll}
                            /></th>
                        </tr>
                    </thead>

                    <tbody id="users-data-body">
                        {loading && (<div className="loader"><DotPulseLoader /></div>)}
                        {Object.entries(filteredSpaces)
                            .filter(([, blessId]) => (
                                selectedCategories.includes('All') || selectedCategories.includes(blessId.status)))
                            .map(([blessId, data]) => (
                                <tr key={blessId}>
                                    <td>{data?.productType}</td>
                                    {checkTableColumnVisiability('accountName') && (<td>{data?.accountName}</td>)}
                                    {checkTableColumnVisiability('installId') && (<td>{data?.installId}</td>)}
                                    <td>{data?.blessId}</td>
                                    <td><div className="text-wrap">{data?.macId}</div></td>
                                    {checkTableColumnVisiability('floorId') && (<td>{data?.floorId}</td>)}
                                    {checkTableColumnVisiability('floorStatus') && (<td>{data?.floorStatus}</td>)}
                                    {checkTableColumnVisiability('space') && (<td>{data?.space}</td>)}
                                    <td><div className="text-wrap">{data?.modelName}</div></td>
                                    <td><div className="text-wrap">{data?.deviceType}</div></td>
                                    {checkTableColumnVisiability('status') && (
                                        <td>
                                            <div className="statusCol" style={{ background: data.bgColor, borderRadius: "20px", padding: "0.2rem 0.5rem", color: "white", height: "2rem", display: "flex", justifyContent: "center", alignItems: "center", width: "5rem" }}>
                                                {data?.status}
                                            </div>
                                        </td>
                                    )}
                                    {migrationInProgress && <td>
                                        <div className="tooltipContainer statusCol" style={{ background: data?.migrationStatus?.bgColor, borderRadius: "20px", padding: "0.2rem 0.5rem", color: "white", height: "2rem", display: "flex", justifyContent: "center", alignItems: "center", minWidth: "6rem" }}>
                                            {data?.migrationStatus?.text}
                                            {
                                                data?.migrationStatus?.text === 'Fail' && (
                                                    <div className='tooltip'>{data?.migrationStatus?.message}</div>
                                                )
                                            }
                                        </div>
                                    </td>}
                                    <td><CheckBox
                                        id={blessId}
                                        handleClick={handleClick}
                                        isChecked={isCheck.includes(blessId)}
                                        disabled={data.status === "Blessed" || data?.migrationStatus?.text === 'Ready' ? false : true}
                                    /></td>
                                </tr>
                            ))}
                    </tbody>

                </table>
                {showDeblessedPopUP ?
                    <div className="col-10 col-sm-6 col-lg-6 mx-auto">
                        <Mod1 text={{ primaryText: "Debless Device", secondaryText: `${liveStatusCount} out of ${spacesLength} devices are assigned on Live Floor. Do you still want to debless it?`, icon: alertIcon }}
                            closeModal={() => setShowDeblessedPopUp(false)}
                            confirmButton={{
                                text: "Yes", onClick: () => {
                                    setShowDeblessedPopUp(false)
                                    setLoginKeyPopUp(true);
                                }
                            }}
                        />
                    </div>
                    : null
                }
                {loginKeyPopUp ?
                    <div className="col-10 col-sm-6 col-lg-6 mx-auto">
                        <Mod1 text={{ secondaryText: modalDetails.secondaryText, icon: freespaceIcon }}
                            // closeModal={() => setGetNumberToTest(false) }
                            confirmButton={{
                                text: modalDetails.confirmButtonText, onClick: deblessedAPICallingFromMacAddress
                            }}
                            inputBox={modalDetails.inputBox}
                            value={modalDetails.inputBox ? login : null}
                            setValue={modalDetails.inputBox ?
                                loginKey
                                : null}
                        />
                    </div>
                    : null
                }
                {modalPopUp ?
                    <div className="col-10 col-sm-6 col-lg-6 mx-auto">
                        <Mod1 text={{ primaryText: "Invalid Credentials", secondaryText: "Please enter valid login key", icon: alertIcon }}
                            // closeModal={() => setModalPopUp(false)}
                            confirmButton={{
                                text: "Try Again", onClick: () => {
                                    setModalPopUp(false)
                                }
                            }}
                        />
                    </div>
                    : null

                }
                {handleEmptyPopUp ?
                    <div className="col-10 col-sm-6 col-lg-6 mx-auto">
                        <Mod1 text={{ primaryText: "Invalid File", secondaryText: "Please enter valid CSV/Excel/JSON file", icon: alertIcon }}
                            // closeModal={() => setModalPopUp(false)}
                            confirmButton={{
                                text: "Okay", onClick: () => {
                                    setHandleEmptyPopUp(false)
                                    setShowFileDropComponent(true)
                                }
                            }}
                        />
                    </div>
                    : null
                }
                {showAddNew ?
                    <div className="addnewDiv">
                        <Mod2
                            text={{ primaryText: "Add New Device" }}
                            closeModal={() => setShowAddNew(false)}
                            confirmButton={{
                                text: "Done", onClick:
                                    addNewFunction

                            }}
                            inputBox={{
                                label: `Enter ${storeType === 'macId' ? "Mac ID" : "Bless ID"}:`
                            }}
                            value={modalDetails.inputBox ? addNewValue : null}
                            setValue={modalDetails.inputBox ?
                                newDevice
                                : null}
                        />
                    </div>
                    : null
                }

                <div className="uploadingDiv">
                    {showFileDropComponent && (
                        <FileDrop
                            onClick={readFileData}
                            setUploadedFileExtension={setUploadedFileExtension}
                        />
                    )}
                </div>


            </div >
            {
                showDialog && <Modal
                    size={dialogData.size || null}
                    header={dialogData?.header}
                    actionIcon={dialogData?.icon}
                    isOpen={showDialog}
                    setIsOpen={setShowDialog}
                    showCloseButton={dialogData.showCloseButton}
                    showActionBar={dialogData.showActionBar}
                    dismissOnClick={dialogData.dismissOnClick}
                >
                    {dialogData?.body}
                </Modal>
            }
        </>
    );
}

export default Requester;